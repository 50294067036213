<template>
  <header
    ref="el"
    class="relative z-0 content-fullscreen-header overflow-hidden"
  >
    <figure
      :style="{
        transform: `translateY(${position * 40}%)`,
      }"
      class="h-full will-change-transform"
    >
      <FieldItemTypeImage
        v-bind="media.image"
        loading="eager"
        preload
        :config="imageStyle"
        img-class="h-full w-full"
      />
    </figure>
    <div
      class="absolute top-0 left-0 size-full z-10 bg-body will-change-[opacity] pointer-events-none"
      :style="{
        opacity: Math.min(Math.max(position - 0.3, 0) * 3, 0.8),
      }"
    />
    <div
      class="bg-white py-30 md:py-50 lg:py-70 xxl:py-100 absolute z-20 left-0 bottom-0 w-full mix-blend-lighten"
    >
      <Container>
        <div :style="style">
          <h1
            class="font-bold text-5xl md:text-5xl lg:text-6xl xl:text-8xl xxl:text-9xl will-change-transform"
          >
            {{ title }}
          </h1>
          <div class="">
            <p
              v-if="stringShouldRender(lead)"
              v-blokkli-editable:field_lead
              class="font-bold text-lg md:text-xl xl:text-2xl mt-30 hyphens-auto lg:hyphens-none"
            >
              {{ lead }}
            </p>
          </div>
        </div>
      </Container>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core'
import type { MediaImageFragment } from '#graphql-operations'
import { stringShouldRender } from '~/helpers/string'

const props = defineProps<{
  media: MediaImageFragment
  title: string
  lead?: string
}>()

// The header element.
const el = ref<HTMLDivElement | null>(null)

// Observe the element's visibility in the viewport.
const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
  // Make sure to only run a single animation loop.
  stopLoop()
  if (isIntersecting) {
    loop()
  }
})

// The requestAnimationFrame ID.
const raf = ref<null | number>(null)

// The current position for the animation, from 0 to 1.
const position = ref(0)

const style = computed(() => {
  // Title or lead exceed a certain character length, then to do not perform any
  // transition.
  if (props.title.length > 50 || (props.lead && props.lead.length > 300)) {
    return {}
  }

  return {
    transform: `translateY(${position.value * 20}%)`,
  }
})

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 400,
      height: 800,
    },
    md: {
      width: 800,
      height: 800,
    },
    lg: {
      width: 1024,
      height: 1024,
    },
    xl: {
      width: 1680,
      height: 1680,
    },
  },
})

// The main animation loop.
function loop() {
  const height = el?.value?.offsetHeight || window.innerHeight
  position.value = Math.min(Math.max(window.scrollY / height, 0), 1)
  raf.value = window.requestAnimationFrame(loop)
}

// Stop the animation loop.
function stopLoop() {
  if (raf.value !== null) {
    window.cancelAnimationFrame(raf.value)
  }
}

onBeforeUnmount(() => {
  stop()
  stopLoop()
})
</script>

<style lang="postcss">
.content-fullscreen-header {
  height: calc(100vh - 10rem);
  @apply max-h-[800px] md:max-h-none;

  @screen md {
    height: calc(100vh + 10rem);
  }

  img {
    @apply h-full object-cover;
  }
}
</style>
